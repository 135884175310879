<template>
    <v-row no-gutters>
      <v-col>
        <div id="data-list" v-if="status">
       
          <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
          <v-card
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
          >
            <v-toolbar :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
              <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
                <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                  {{ icons.mdiFileDocumentEdit }}
                </v-icon>
               Satış Sözleşmeleri
              </v-toolbar-title>
  
              <v-spacer></v-spacer>
  
            <!--   <download-excel
                v-if="!$vuetify.breakpoint.smAndDown"
                :data="json_data"
                :fields="json_fields"
                name="AGING_REPORT__TUKAS.xls"
              >
                <v-btn plain text small>
                  <v-icon left>
                    {{ icons.mdiDownload }}
                  </v-icon>
                  İndir
                </v-btn>
              </download-excel> -->
      
            </v-toolbar>
  
            <!-- table -->
            <v-data-table
              v-model="selectedRows"
              :headers="tableColumns"
              :items="dataListTable"
              :options.sync="options"
              :items-per-page="15"
              fixed-header
              :height="dataTableHeight"
              :loading="loading"
            >
              <template #[`item.DOCTYPE`]="{ item }">
                <v-chip small label color="grey">
                  <span class="black--text"> {{ item.DOCTYPE }}</span>
                </v-chip>
              </template>
              <template #[`item.DOCNUM`]="{ item }">
                <v-chip small label color="grey">
                  <span class="black--text"> {{ item.DOCNUM }}</span>
                </v-chip>
              </template>
              <template #[`item.CUSTOMER`]="{ item }">
                <v-chip small label color="grey">
                  <span class="black--text"> {{ item.CUSTOMER }}</span>
                </v-chip>
              </template>
              <template #[`item.NAME1`]="{ item }">
                <v-chip small label color="grey">
                  <span class="black--text"> {{ item.NAME1 }}</span>
                </v-chip>
              </template>
              <template #[`item.ITEMNUM`]="{ item }">
                <v-chip small label color="grey">
                  <span class="black--text"> {{ item.ITEMNUM }}</span>
                </v-chip>
              </template>
              <template #[`item.MTEXT`]="{ item }">
                <v-chip small label color="grey">
                  <span class="black--text"> {{ item.MTEXT }}</span>
                </v-chip>
              </template>
              <template #[`item.COMPONENT`]="{ item }">
                <v-chip small label color="grey">
                  <span class="black--text"> {{ item.COMPONENT }}</span>
                </v-chip>
              </template>

              
              <template #[`item.TUK1QUANTITY`]="{ item }">
                <span class="success--text text-caption font-weight-bold"> {{ item.TUK1QUANTITY | currency }}</span>
              </template>
  
           
              <template #[`item.TUK2QUANTITY`]="{ item }">
                <v-chip small label color="grey">
                  <span class="error--text text-caption font-weight-bold"> {{ item.TUK2QUANTITY | currency}}</span>
                </v-chip>
              </template>
              <template #[`item.TUK3QUANTITY`]="{ item }">
                <v-chip small label color="grey">
                  <span class="error--text text-caption font-weight-bold"> {{ item.TUK3QUANTITY | currency}}</span>
                </v-chip>
              </template>
              <template #[`item.TUK1STOCK`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.TUK1STOCK | currency }}</span>
                </v-chip>
              </template>
              <template #[`item.TUK2STOCK`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.TUK2STOCK | currency }}</span>
                </v-chip>
              </template>
              <template #[`item.TUKKORDER`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.TUKKORDER | currency }}</span>
                </v-chip>
              </template> 
              <template #[`item.TUKMORDER`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.TUKMORDER | currency }}</span>
                </v-chip>
              </template> 
              <template #[`item.TUTAR`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.TUTAR  }}</span>
                </v-chip>
              </template> 
        
              
              <template #[`item.CURRENCY`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.CURRENCY }}</span>
                </v-chip>
              </template> 
              <template #[`item.CREATEDAT`]="{ item }">
                <span class="text--secondary text-caption"> {{ item.CREATEDAT.substring(0, 16) }}</span>
              </template>           
             
            </v-data-table>
          </v-card>
        </div>
        <div v-else>
          <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
          <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
            Lütfen yukarıdan bir müşteri seçin
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import CustomerList from '@/components/CustomerList.vue'
  import store from '@/store'
  import {
    mdiCamera,
    mdiCameraOff,
    mdiClose,
    mdiFileDocumentEdit,
    mdiDelete,
    mdiDownload,
    mdiEye,
    mdiFilter,
    mdiFilterOff,
    mdiPlusThick,
    mdiSend,
    mdiSquareEditOutline,
  } from '@mdi/js'
  import { onMounted, ref, watch } from '@vue/composition-api'
  import Vue from 'vue'
  
  export default {
    components: {    
      CustomerList,
    },
    setup() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const status = ref(false)
      const sendData = ref({})
      onMounted(() => {
        if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
          status.value = false
        } else {
          status.value = true
          loading.value = true
          fetchDatas()
        }
      })
  
      watch(
        () => store.state.plasiyerCustomer,
        () => {
          if (store.state.plasiyerCustomer) {
            status.value = true
            loading.value = true
            fetchDatas()
          }
        },
      )
  
      const dataListTable = ref([])
      const dataItem = ref({})
      let tableColumns
       if (userData.role == 'PLSYR') {
        tableColumns = [
          {
            text: 'Belge Tipi',
            value: 'DOCTYPE',
            width: '90px',
            sortable: false,
          },
           {
            text: 'Belge No',
            value: 'DOCNUM',
            width: '90px',
            sortable: false,
          },
          {
            text: 'Müşteri Kodu',
            value: 'CUSTOMER',
            width: '150px',
          },
          {
            text: 'Müşteri Adı',
            value: 'NAME1',
            width: '150px',     
          },
          {
            text: 'Kalem No',
            value: 'ITEMNUM',
            width: '120px',     
            //align: 'left min-width-400',
          },
          {
            text: 'Ürün Kodu',
            value: 'MATERIAL',
            width: '120px',
            align: 'right',
            sortable: false,
          },
  
          {
            text: 'Ürün Adı',
            value: 'MTEXT',
            width: '150px',
            align: 'center',
          },
          {
            text: 'Mamül Kodu',
            value: 'COMPONENT',
            width: '100px',
            align: 'center',
          },
          {
            text: 'Sözleşme Mik.Adet',
            value: 'TUK1QUANTITY',
            width: '150px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Giden Mik.Adet',
            value: 'TUK2QUANTITY',
            width: '150px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Kalan Miktar',
            value: 'TUK3QUANTITY',
            width: '150px',
            align: 'center',
            sortable: false,
          },
  
          {
            text: 'Kolili Stok',
            value: 'TUK1STOCK',
            sortable: false,
            width: '100px',
          }, 
          {
            text: 'Dökme Stok',
            value: 'TUK2STOCK',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: "K'li Açık İş Emri",
            value: 'TUKKORDER',
            width: '150px',
            align: 'center',
            sortable: false,
          },
       
          {
            text: "M'li Açık İş Emri",
            value: 'TUKMORDER',
            width: '150px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Tutar',
            value: 'TUTAR',
            width: '100px',
            align: 'center',
            sortable: false,
          },   
        
          {
            text: 'P.Br.',
            value: 'CURRENCY',
            width: '60px',
            align: 'center',
            sortable: false,
          }, 
          {
            text: 'Oluşturma Tarihi',
            value: 'CREATEDAT',
            width: '150px',
            align: 'center',
            sortable: false,
          },
          
        ]
      } 
  
      const totalDataListTable = ref(0)
      const loading = ref(false)
      const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
      })
      const dataTotalLocal = ref([])
      const selectedRows = ref([])
  
      const json_data = ref([])
  
      const fetchDatas = () => {
        
        store
        .dispatch('postMethod', {
            method: 'getSalContractList',
          })
          .then(response => {
            if (response.error == 0) {
              
              dataListTable.value = response.detail
             // totalDataListTable.value = Number(response.response.pagination.totalRec)
              json_data.value = response.detail.result
  
              loading.value = false
            } else {
              loading.value = false
              Vue.swal({
                title: 'Hata',
                text: 'Bir Hata Oluştu',
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            }
          })
          .catch(e => {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          })
      }
     
  
  
      
  
      return {
        sendData,
        json_data,
        dataItem,
        dataListTable,
        tableColumns,
        totalDataListTable,
        loading,
        options,
        dataTotalLocal,
        selectedRows,
        fetchDatas,
        status,
        userData,
        // icons
        icons: {
          mdiCamera,
          mdiDelete,
          mdiCameraOff,
          mdiFileDocumentEdit,
          mdiEye,
          mdiFilter,
          mdiFilterOff,
          mdiPlusThick,
          mdiSquareEditOutline,
          mdiClose,
          mdiDownload,
          mdiSend,
        },
      }
    },
    data() {
      return {
        panel: null,
        date: null,
        modal: false,
        filterStatus: false,
        dataTableHeight: '',
        height: {
          system: 0,
          top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
          footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
        },
        json_fields: {
          ID: 'id',
          'Kart No': 'cc_no',
  
          Fiyat: {
            field: 'amount',
            callback: value => {
              return `${Number(value.slice(0, -1))}`
            },
          },
          'Taksit Sayısı': {
            field: 'installment_count',
            callback: value => {
              return value == 1 ? 'Tek Çekim' : value + 'Taksit'
            },
          },
          'İşlem Tarihi': {
            field: 'datetime',
            callback: value => {
              return `${value.slice(0, 16)}`
            },
          },
          Açıklama: 'ltext',
        },
  
        json_meta: [
          [
            {
              key: 'charset',
              value: 'utf-8',
            },
          ],
        ],
      }
    },
  
    watch: {
      panel(val) {
        if (val === 0) {
          setTimeout(() => {
            this.height.top = 250
            this.onResize()
          }, 300)
        } else {
          this.height.top = 48
          this.onResize()
        }
      },
    },
  
    mounted() {
      this.$nextTick(() => {
        this.onResize()
        window.addEventListener('resize', this.onResize)
      })
    },
  
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize)
    },
    methods: {
      onResize() {
        this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/preset/preset/apps/user.scss';
  </style>
  